import styled from "styled-components";
const PhuggitAppByDylanSchiff = styled.div``;
const App = () => {
    return <PhuggitAppByDylanSchiff></PhuggitAppByDylanSchiff>;
};
export default App;

// appplan

// clean up colors
// build out passthru structure

import { useState, createContext } from "react";

export const NavigationContext = createContext({
    viewedProject: null,
    setViewedProject: () => null,
});

export const NavigationProvider = ({ children }) => {
    const [viewedProject, setViewedProject] = useState();
    const value = {
        viewedProject,
        setViewedProject,
    };
    return (
        <NavigationContext.Provider value={value}>
            {children}
        </NavigationContext.Provider>
    );
};
